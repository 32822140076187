import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators/tap';
import { environment } from '../../../environments/environment';
import { TokenService } from './token.service';
import { IHttpResponse } from '../interfaces/http-response.interface';
import { UserModel } from '../models/user.model';

export interface AuthData {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenService: TokenService = inject(TokenService);
  private http: HttpClient = inject(HttpClient);

  authEvent: BehaviorSubject<any>;
  currentUser: UserModel = {} as UserModel;

  private readonly api = `${environment.apiUrl}/api/auth`;

  get isSigned(): boolean {
    return !!(this.tokenService.token || false);
  }

  get window(): any {
    return window;
  }

  auth(q: string = ''): Observable<UserModel> {
    return this.http
      .get<IHttpResponse>(`${this.api}/user?date=${Date.now()}&${q ? q : ''}`)
      .pipe(
        tap((data: any) => {
          if (data.token) {
            this.setToken(data.token);
            this.currentUser = UserModel.fromJson(data);
          }
        })
      );
  }

  setToken(token: string) {
    this.tokenService.setToken(token);
  }
}
