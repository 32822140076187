import { Model } from '../abstract/model.abstract';

export class UserModel extends Model {
  id: number;
  email: string;
  telegramId: string;
  profile: {
    firstName: string;
    lastName: string;
    nickname: string;
    avatar: string;
  };
  coin: {
    amount: number;
    lastEnergy: number;
    characterAmount: number;
    updatedAt: Date;
  };
  balance: {
    amount: number;
  };
  boosters: {
    damageFree: number;
    energyFree: number;
  };
  activeBoosters: {
    id: number;
    boost: number;
    typeId: number;
    endAt: Date;
  }[];
  account: {
    damage: number;
    energy: number;
    rechargingSpeed: number;
    rechargings: number;
    turbo: number;
    tapBot: boolean;
    tapBotHarvest: Date;
    lastRecharging: Date;
    turboExpiredAt: Date;
    updatedAt: Date;
    character: any;
  };

  get name() {
    const fullName = `${this.profile.firstName || ''} ${
      this.profile.lastName || ''
    }`;

    return fullName.trim().length
      ? fullName
      : this.profile.nickname
      ? this.profile.nickname
      : this.telegramId;
  }

  get totalCoins() {
    if (!this?.coin?.amount) {
      return '0';
    }
    let formatter = Intl.NumberFormat('en', { notation: 'standard' });
    const num = this?.coin?.amount;
    return formatter.format(num);
  }
}
